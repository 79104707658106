export const environment = {
  production: true,
  apiUrl: 'https://api2.payconnect.com/v1',
  tokenizerBaseUrl: 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html',
  userInactivityTimeInMinutes: 28,
  userTimeoutDelayInMinutes: 2,
  SPROUTVIDEO_URL:'https://videos.sproutvideo.com',
  env: 'production',
  releaseTag: 'payconnect-platform@1300',
  sentryDSN: 'https://87b120e181614fe6a607b137de2a3aab@sentry.prod.dentalxchange.com/70'
};
